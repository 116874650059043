/* 菜单数据是动态的，注意的是 id 和 路由的
path(或者name)保持一致，页面刷新要用到：实现了页面刷新路由保持不变和菜单也是当前点击的高亮状态 */
<template>
  <div class="nav-wrapper">
    <el-menu
      :default-active="navMenu.activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      router
      text-color="#666"
      active-text-color="#333"
      menu-trigger="click"
    >
      <el-menu-item
        v-for="(item, index) in navMenu.navList"
        :key="index"
        :index="item.id"
        :route="item.path"
        >{{ item.label }}</el-menu-item
      >
      <el-submenu index="4">
        <template slot="title">{{ navMenu.navMenuTitle }}</template>
        <el-menu-item
          class="navMenuClass"
          v-for="item in navMenu.navMenuItem"
          :key="item.id"
          index="Contact"
          :route="{ path: item.path, query: { params: $Base64.encode(item.id) } }"
        >
          <span v-if="item.id !== 'apply'">{{ item.title }}</span>
          <span v-else @click="toSmarkApply">
            {{ item.title }}
          </span></el-menu-item
        >
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navMenu: {
        activeIndex: '',
        navMenuTitle: '联系我们',
        navList: [
          {
            id: 'Home',
            path: '/home',
            label: '首页'
          },
          {
            id: 'Introduction',
            path: '/introduction',
            label: '简介'
          },
          {
            id: 'Profession',
            path: '/profession',
            label: '业务'
          },
          {
            id: 'Responsibility',
            path: '/responsibility',
            label: '企业责任'
          }
        ],
        navMenuItem: [
          {
            id: 'platform',
            path: 'contact',
            title: '平台入驻'
          },
          {
            id: 'apply',
            title: '试用账号申请'
          },
          {
            id: 'docking',
            path: 'contact',
            title: '开发者对接'
          },
          {
            id: 'feedback',
            path: 'contact',
            title: '意见反馈'
          }
        ]
      }
    };
  },
  methods: {
    setCurrentRoute() {
      this.navMenu.activeIndex = this.$route.name; //关键 通过他就可以监听到当前路由状态并激活当前菜单
    },
    toSmarkApply() {
      /* 打开新的窗口 */
      window.open('http://36.138.233.147:8070/#/register/applytrial', '_blank');
    }
  },
  created() {
    this.setCurrentRoute();
  },
  watch: {
    $route() {
      this.setCurrentRoute();
    }
  }
};
</script>

<style lang="less" scoped>
/deep/.el-menu.el-menu--horizontal {
  border: 0;
}
/deep/.el-menu-item {
  padding: 0 36px;
  font-size: 16px;
}
/deep/.el-menu-item.is-active {
  font-weight: bolder;
  border-bottom: 2px solid #1d7df8 !important;
}
/deep/.el-submenu.is-active .el-submenu__title {
  border-bottom: 2px solid #1d7df8 !important;
  font-weight: bolder;
}
/deep/.el-submenu__title {
  font-size: 16px;
}
.navMenuClass:hover {
  background: #f8f8f8 !important;
  color: #1d7df8 !important;
}
</style>

<style>
.el-menu--popup {
  min-width: 128px !important;
}
</style>
