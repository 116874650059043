import Vue from 'vue';
import {
  Form,
  Button,
  FormItem,
  Input,
  Message,
  Menu,
  Submenu,
  MenuItem,
  Icon,
  TableColumn,
  Pagination,
  Table
} from 'element-ui';
Vue.use(Form);
Vue.use(Button);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(Button);
Vue.use(Icon);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Table);
Vue.prototype.$message = Message;
