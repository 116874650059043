import Vue from 'vue';
import axios from 'axios';
import { VueAxios } from './axios';

axios.defaults.withCredentials = true;
Vue.use(VueAxios, axios);
// 创建 axios 实例
const service = axios.create({
  baseURL: '/api', // api base_url
  timeout: 30000 // 请求超时时间
});

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, service);
  }
};

export { installer as VueAxios, service as axios };
