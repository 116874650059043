<template>
  <div class="header-wrapper">
    <div class="header">
      <div class="logo">众望智慧科技</div>
      <div class="nav">
        <Nav></Nav>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from './Nav.vue';
export default {
  components: {
    Nav
  },
  data() {
    return {};
  }
};
</script>

<style lang="less" scoped>
.header-wrapper {
  height: 70px;
  background-color: #fff;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 1200px;
    margin: 0 auto;
    // background-color: pink;

    .logo {
      padding-left: 56px;
      font-size: 28px;
      font-weight: 500;
      line-height: 70px;
      color: #333333;
      background: url('~@/assets/images/logo.png') no-repeat left center;
      background-size: 56px;
    }
  }
}
</style>
