/*
 * @Author: your name
 * @Date: 2021-01-05 09:44:03
 * @LastEditTime: 2021-01-08 16:18:25
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \guanwang\zhongwang-index-vue\src\config\index.js
 */
export default {
  base_url: '/api',
  bm_lbs_key: 'QDMI2VFMUoHL8YE2s8OjLubuts3WTrui'
};
