import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/views/layout/Layout.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'App',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/pages/home/home.vue')
      },
      {
        path: '/introduction',
        name: 'Introduction',
        component: () => import('@/views/pages/introduction/introduction.vue')
      },
      {
        path: '/profession',
        name: 'Profession',
        component: () => import('@/views/pages/profession/profession.vue')
      },
      {
        path: '/responsibility',
        name: 'Responsibility',
        component: () => import('@/views/pages/responsibility/responsibility.vue')
      },
      {
        path: '/contact',
        name: 'Contact',
        component: () => import('@/views/pages/contact/contact.vue')
      }
    ]
  },
  //用于查看申请人填写的信息
  {
    path: '/applyUserInfo',
    name: 'ApplyUserInfo',
    component: () => import('@/views/pages/applyUserInfo')
  }
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    };
  }
});

export default router;
