<template>
  <div class="footer">
    <div class="footer-wrapper">
      <div class="wrapper-content"></div>
      <img
        style="height: 386px; width: 100%; position: absolute; top: 0"
        src="@/assets/images/footer-bg.png"
      />
      <div class="mask"></div>
      <div class="wrapper-word" style="opacity: 1">
        <div class="word-content">
          <div class="content-title">
            <img style="height: 56px" src="@/assets/images/footer-logo.png" alt />
            <p>成都众望智慧科技有限公司</p>
          </div>
          <div class="content-intro">
            <div class="partOne">
              <div class="partOne-title">
                <span style="font-size: 18px; font-weight: bold; color: #ffffff">快速链接</span>
              </div>
              <div class="partOne-content">
                <ul class="intro-content">
                  <li class="title">简介</li>
                  <li class="content" @click="handleJumpToIntro('/introduction', 0)">公司简介</li>
                  <li class="content" @click="handleJumpToIntro('/introduction', 1)">愿景使命</li>
                  <li class="content" @click="handleJumpToIntro('/introduction', 2)">发展历程</li>
                  <li class="content" @click="handleJumpToIntro('/introduction', 3)">企业文化</li>
                </ul>
                <ul class="intro-content">
                  <li class="title">业务</li>
                  <li class="content" @click="handleJumpToIntro('/profession', 0)">面向园区</li>
                  <li class="content" @click="handleJumpToIntro('/profession', 1)">面向政府</li>
                  <li class="content" @click="handleJumpToIntro('/profession', 2)">面向企业</li>
                </ul>
                <ul class="intro-content">
                  <li class="title">企业责任</li>
                  <li class="content" @click="handleJumpToIntro('/responsibility', 0)">全球抗疫</li>
                  <li class="content" @click="handleJumpToIntro('/responsibility', 1)">
                    大智慧环保
                  </li>
                </ul>
                <div class="line"></div>
              </div>
            </div>
            <div class="partTwo">
              <div class="partTwo-title">
                <span style="font-size: 18px; font-weight: bold; color: #ffffff">快速链接</span>
              </div>
              <div class="partTwo-content">
                <ul class="intro-content">
                  <li class="title">加入我们</li>
                  <li @click="handleJumpToIntro('/contact', 'platform')" class="content">
                    平台入驻
                  </li>
                  <li class="content" @click="handleJumpToTry">申请试用</li>
                  <li @click="handleJumpToIntro('/contact', 'docking')" class="content">
                    开发者对接
                  </li>
                </ul>
                <ul class="intro-content">
                  <li class="title">联系我们</li>
                  <li class="content" @click="handleJumpToIntro('/contact', 'platform')">
                    客户服务
                  </li>
                  <li class="content" @click="handleJumpToIntro('/contact', 'platform')">
                    合作洽谈
                  </li>
                  <li class="content" @click="handleJumpToIntro('/contact', 'feedback')">
                    意见反馈
                  </li>
                </ul>
                <div class="line"></div>
              </div>
            </div>
            <div class="partThree">
              <div class="partThree-title">
                <span style="font-size: 18px; font-weight: bold; color: #ffffff">关注我们</span>
              </div>
              <div class="qr-code-wrapper">
                <div class="qr-code">
                  <img style="width: 118px" src="@/assets/images/qr-code.png" alt />
                  <div class="panel-footer"></div>
                </div>

                <p style="font-size: 12px; font-weight: 400; color: #ffffff; opacity: 0.7">
                  扫TA了解更多信息
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="wrapper-foot">
        <p>
          Copyright © @2018-2020 成都众望智慧科技有限公司
          <span class="foot-url" @click="handleClickLink">蜀ICP备17031917号-2</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    // 路由跳转
    handleJumpToIntro(path, params) {
      if (typeof params === 'string') {
        params = this.$Base64.encode(params);
      }
      this.$router.push({ path, query: { params } }).catch(err => err);
    },
    // 跳转试用
    handleJumpToTry() {
      window.open('http://36.138.233.147:8070/#/register/applytrial', '_blank');
    },
    handleClickLink() {
      window.open('https://beian.miit.gov.cn/#/Integrated/index');
    }
  }
};
</script>

<style lang="less" scoped>
.footer {
  height: 425px;
  position: relative;

  .mask {
    height: 386px;
    width: 100%;
    position: absolute;
    background: #000000;
    opacity: 0.7;
    top: 0;
  }
  .wrapper-word {
    position: absolute;
    width: 100%;
    height: 386px;
    top: 0;

    .word-content {
      width: 1200px;
      margin: 0 auto;
      .content-title {
        display: flex;
        height: 56px;
        margin-top: 24px;
        margin-bottom: 34px;

        p {
          display: inline-block;
          margin-top: 10px;
          color: #ffffff;
          font-size: 26px;
        }
      }

      .content-intro {
        height: 270px;
        margin-left: 13px;
        display: flex;
        .partOne {
          width: 491px;
          display: inline-block;
          .partOne-title {
            width: 100px;
            height: 31px;
            border-bottom: 4px solid #1f98c9;
            margin-bottom: 24px;
          }
          .partOne-content {
            width: 441px;
            height: 179px;
            margin-left: 50px;
            margin-top: 24px;
            display: flex;
            .intro-content {
              width: 71px;
              height: 157px;
              margin-top: 18px;
              margin-right: 76px;
              .title {
                font-size: 16px;
                font-weight: bold;
                color: #ffffff;
                margin-bottom: 15px;
              }
              .content {
                font-size: 14px;
                cursor: pointer;
                color: #5e6679;
                margin-bottom: 13px;
              }
              .content:hover {
                color: #1d7df8ff;
              }
            }
            .line {
              width: 0px;
              height: 179px;
              border: 1px solid #ffffff;
            }
          }
        }
        .partTwo {
          width: 391px;
          display: inline-block;
          .partTwo-title {
            width: 100px;
            height: 31px;
            border-bottom: 4px solid #1f98c9;
            margin-bottom: 24px;
            margin-left: 48px;
          }
          .partTwo-content {
            height: 123px;
            display: flex;
            margin-left: 98px;
            margin-right: 6px;

            .intro-content {
              width: 71px;
              height: 123px;
              margin-top: 18px;
              margin-right: 76px;
              .title {
                font-size: 16px;
                font-weight: bold;
                color: #ffffff;
                margin-bottom: 15px;
              }
              .content {
                width: 71px;
                font-size: 14px;
                cursor: pointer;
                color: #5e6679;
                font-weight: 400;
                margin-bottom: 13px;
              }
              .content:hover {
                color: #1d7df8ff;
              }
            }
          }
          .line {
            width: 0px;
            height: 179px;
            border: 1px solid #ffffff;
          }
        }
        .partThree {
          width: 391px;
          display: inline-block;
          .partThree-title {
            width: 100px;
            height: 31px;
            border-bottom: 4px solid #1f98c9;
            margin-bottom: 24px;
            margin-left: 57px;
          }
          .qr-code-wrapper {
            position: relative;
            width: 150px;
            height: 170px;
            margin-left: 100px;
            margin-top: 30px;
            display: flex;
            align-items: center;
            flex-direction: column;
            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 20px;
              height: 20px;
              border-left: 1px solid #02a6b5;
              border-top: 1px solid #02a6b5;
            }
            &::after {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              width: 20px;
              height: 20px;
              border-right: 1px solid #02a6b5;
              border-top: 1px solid #02a6b5;
            }
            .qr-code {
              position: relative;
              box-sizing: border-box;
              margin-bottom: 6px;
              padding: 16px 16px 15px 16px;
              .panel-footer {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;

                &::before {
                  content: '';
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 20px;
                  height: 20px;
                  border-left: 1px solid #02a6b5;
                  border-bottom: 1px solid #02a6b5;
                }
                &::after {
                  content: '';
                  position: absolute;
                  bottom: 0;
                  right: 0;
                  width: 20px;
                  height: 20px;
                  border-right: 1px solid #02a6b5;
                  border-bottom: 1px solid #02a6b5;
                }
              }
            }
          }
        }
      }
    }
  }

  .footer-wrapper {
    height: 425px;
    background: #000000;

    .wrapper-content {
      position: relative;
      width: 1200px;
      margin: 0 auto;
      height: 386px;
    }

    .wrapper-foot {
      height: 40px;
      text-align: center;
      line-height: 40px;
      background: #000000;
      p {
        font-size: 12px;
        opacity: 0.5;
        color: #ffffff;
      }
      .foot-url:hover {
        cursor: pointer;
        color: #1d7df8ff;
      }
    }
  }
}
</style>
