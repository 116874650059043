/*
 * @Author: your name
 * @Date: 2021-01-05 09:44:03
 * @LastEditTime: 2021-01-05 10:08:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \guanwang\zhongwang-index-vue\src\main.js
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './plugins/element.js';
import '@/assets/css/normalize.css';
import config from './config';
import BaiduMap from 'vue-baidu-map';
import './utils/request.js';
Vue.config.productionTip = false;

import Base64 from '@/utils/base64.js';

Vue.prototype.$Base64 = Base64;
// 引用fullpage 插件
import 'fullpage.js/vendors/scrolloverflow';
import VueFullPage from 'vue-fullpage.js';
Vue.use(VueFullPage);
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: config.bm_lbs_key
});
new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
